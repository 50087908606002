@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  grid-template-rows: repeat(2, 200px);
  grid-gap: 16px;
  gap: 16px;
}
