@import '@/styles/variables.scss';

.listingFilterContainer {
  display: grid;

  &__button {
    grid-gap: $spacing-3;
  }

  &__standard {
    grid-gap: $spacing-5;
  }

  &__col2 {
    grid-template-columns: 1fr 1fr;
  }

  &__col3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__col4 {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }

  &_filter {
    display: flex;
    align-items: center;
    gap: $spacing-1;
    text-wrap: nowrap;
  }
}
