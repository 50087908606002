@import "@/styles/variables";
@import "@/styles/mixins";

.footer {
  align-items: center;
  padding: $spacing-7 $spacing-4 $spacing-8 $spacing-4;
  font-size: $font-size-s;

  &__isUserLogged {
    display: none;
  }
}

.mediaLinks, .links {
  text-align: center;

  .footerLink {
    padding: 0 $spacing-1;

    &:hover {
      color: $color-green-main;
      text-decoration: underline;
    }
  }
}

.mediaLinks {
  font-size: $font-size-xxl;
  margin-bottom: $spacing-7;

  .footerLink {
    color: $font-color-grey;
    height: 40px;
  }
}

.links {
  margin-bottom: $spacing-4;

  .footerLink {
    display: inline-block;
    color: $color-gray-dark;
    padding: $spacing-1 $spacing-3;
  }
}

.copyRight {
  text-align: center;
  color: $color-gray-dark;
}

@include for-screen-from-480 {
  .footer__isUserLogged {
    display: block;
  }
}

@include for-screen-from-568 {
  .links {
    .footerLink {
      padding: 0 $spacing-1;
    }
  }
}
