@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingIconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-6;
  padding: $spacing-3;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  box-shadow: $shadow;
  color: $color-grey4;
  transition: color $transition-time $transition-variant, border-color $transition-time $transition-variant;

  svg {
    height: 80px;
  }

  &:hover {
    color: $color-black;
    border-color: $color-black;
  }

  &_imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }

  &_name {
    font-size: $font-size-l;
    text-align: center;
  }
}

@include for-screen-from-360 {
  .listingIconBox {
    &_imageWrapper {
      width: 100px;
      height: 100px;
    }

    svg {
      height: 100px;
    }
  }
}

@include for-screen-from-568 {
  .listingIconBox {
    &_name {
      font-size: $font-size-xxl;
      text-align: center;
    }
  }
}
