@import "@/styles/variables";
@import "@/styles/mixins";

.appDownload {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: $spacing-7 $spacing-4 $spacing-5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-5;
  background: #fff;
  z-index: $z-index-maximal;


  &_text {
    font-size: 26px;
    text-align: center;
  }

  &_download {
    background-color: $color-black;
    color: #fff;
    border-radius: $border-radius-base;
    padding: $spacing-4 $spacing-7;
    font-size: $font-size-xl;
  }
}

.phoneAuth {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_title {
    font-weight: 400;
    font-size: 26px;
  }

  &_subTitle {
    margin-top: $spacing-1;
    font-size: 22px;
    font-weight: 400;
  }

  &_text {
    margin-top: $spacing-3;
  }

  &_sentNumber {
    display: flex;
    gap: $spacing-3;
  }

  &_form {
    margin-top: $spacing-6;
  }

  &_box {
    padding: $spacing-4 $spacing-6;
    width: 100%;
  }

  &_enterCode {
    width: fit-content;
    margin: 0 auto;
  }

  &_formPhone {
    margin-bottom: $spacing-5;
    margin-top: $spacing-5;
  }

  &_support {
    display: flex;
    gap: $spacing-2;
    align-items: center;
    justify-content: center;
  }

  &_supportButton {
    color: $color-green-main;
    font-weight: 500;
    cursor: pointer;
  }

  @include for-screen-from-480 {
    &_box {
      border-radius: $border-radius-base;
      box-shadow: 0 6px 16px rgba(0,0,0,.12);
      padding: $spacing-8 $spacing-7;
      width: auto;
    }

    &_title {
      font-size: $font-size-xxxl;
    }

    &_subTitle {
      font-size: 26px;
    }

    &_enterCode {
      width: fit-content;
      margin: 0 auto;
    }
  }
}
