@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.filterDropdown {
  @include hide();

  background-color: #fff;
  border: $border-width-base solid $color-black;
  box-shadow: $shadow;
  border-radius: $border-radius-base;
  overflow-y: scroll;
  height: calc(100dvh - 241px);
  -webkit-overflow-scrolling: touch;

  &__isActive {
    @include show();

    position: fixed;
    left: 0;
    right: 0;
    width: 100dvw;
    z-index: $z-index-dropdown;
    top: 238px;
  }

  &_top {
    position: sticky;
    z-index: 5;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-3;
    background-color: #fff;
    border-bottom: $border-width-base solid $color-gray;
  }

  &_title {
    font-size: $font-size-l;
    font-weight: 500;
    padding: $spacing-4;
  }

  &_closeIcon {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
    font-size: 20px;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: 0;
    background: none;
    border: none;

    &:hover {
      color: $color-black;
    }
  }

  &_content {
    padding: $spacing-6 $spacing-5;
  }

  &_bottom {
    position: sticky;
    z-index: 5;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $spacing-4;
    gap: $spacing-3;
    border-top: $border-width-base solid $color-gray;
    background-color: #fff;
  }
}

@include for-screen-from-768 {
  .filterDropdown {
    top: 184px;
  }
}

@include for-screen-from-1024 {
  .filterDropdown {
    height: auto;

    &__isActive {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
    }

    &__sm {
      width: 300px;
    }

    &__md {
      width: 480px;
    }

    &_content {
      height: auto;
    }
  }

  [dir="rtl"] {
    .filterDropdown {
      &__isActive {
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
      }

      &__sm {
        width: 300px;
      }

      &__md {
        width: 480px;
      }
    }
  }
}

